export const clientsList = [
    "NBC-10 Consumer Expo",
    "The Walt Disney Company",
    "PepsiCo",
    "American Century Investments",
    "Wells Fargo",
    "NBA All-Star Weekend",
    "Discovery Communications",
    "Vodacom South Africa",
    "AT&T",
    "Maximum Impact",
    "GAP",
    "State Farm",
    "The Bayer Group",
    "AOL",
    "U.S. Marine Corps",
    "Target",
    "Capital One",
    "General Mills",
    "Six Flags, Inc.",
    "Procter & Gamble",
    "Microsoft",
    "ESPN",
    "Gatorade",
    "NCAA",
    "SKYY Spirits",
    "Kaboom!",
    "Google",
];
