export const theme = {
  
  fonts: {
    body: "FreightMicroProBook-Regular, serif",
    bodyItalic:  "FreightMicroProBook-Italic, serif",
    heading:  "FreightSansCndPro-Semi, sans-serif",
  },

  typography: {
    size: "clamp(19px, 1.6vw, 25px)",
    xsmall: "0.6315789474em",
    small: "0.7894736842em",
    lineHeight: 1.65,
    scale1: "5.653em",
    scale2: "4em",
    scale3: "2.827em",
    scale4: "2em",
    scale5: "1.414em",
    scale6: "1em",
  },
  
  colors: {
    white: "#FFFFFF",
    black: "#2A2A2A",
    gray: "#6A6864",
    red: "#E2263F",
    beige: "#f3f4f5",
  },

  sizes: {
    xxs  : 0,
    xs   : 375,
    s    : 414,
    m    : 640,
    l    : 768,
    xl   : 1024,
    xxl  : 1280,
    xxxl : 1440,
  },

}