import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

function HeaderLogo(props) {
    const themeContext = useContext(ThemeContext);

    return (
        <WordMark viewBox="0 0 209 10">
            <path
                d="M130.806 9.77536V8.52043H131.757L131.783 8.45436L129.511 5.27078L127.886 6.7767V8.33547L127.913 8.40155L129.194 8.82428V9.77536H125.31V8.52043H126.446L126.473 8.45436V1.7569L126.446 1.69083L125.152 1.26814V0.317017H129.049V1.57194H127.913L127.886 1.6248V5.42929L131.77 1.70405V1.66442L131.255 1.46628L130.806 1.26814V0.317017H134.412V1.57194H133.606L133.527 1.61157L130.489 4.38569L133.448 8.33547L133.54 8.41473L134.755 8.82428V9.77536H130.806Z"
                fill={themeContext.colors.red} />
            <path
                d="M141.44 1.7041H141.136L139.96 5.58786H142.549L141.44 1.7041ZM142.417 9.77541V8.52048H143.461L143.487 8.45441L142.906 6.67109H139.59L139.062 8.33552L139.102 8.4016L140.198 8.82433V9.77541H136.737V8.52048H137.741L137.767 8.45441L140.277 1.0436V0.449136L142.219 0L144.993 8.32234L145.073 8.4016L146.235 8.8111V9.76223H142.417V9.77541Z"
                fill={themeContext.colors.red} />
            <path
                d="M155.588 3.46101L155.257 1.98149C155.165 1.61161 155.046 1.4531 153.17 1.4531H152.985V8.33551L152.999 8.40159L154.452 8.82432V9.7754H150.224V8.52047H151.545L151.585 8.4544V1.43988H151.321C149.3 1.43988 149.154 1.63803 149.062 2.10038L148.824 3.44783H147.754L148.032 0.303833H156.605V3.44783H155.588V3.46101Z"
                fill={themeContext.colors.red} />
            <path
                d="M162.866 1.7041H162.563L161.387 5.58786H163.976L162.866 1.7041ZM163.844 9.77541V8.52048H164.888L164.914 8.45441L164.346 6.67109H161.03L160.502 8.33552L160.528 8.4016L161.638 8.82433V9.77541H158.177V8.52048H159.181L159.207 8.45441L161.717 1.0436V0.449136L163.659 0L166.433 8.32234L166.512 8.4016L167.675 8.8111V9.76223H163.844V9.77541Z"
                fill={themeContext.colors.red} />
            <path
                d="M177.054 9.7754H170.026V8.52047H171.162L171.189 8.4544V1.74372L171.162 1.67769L169.868 1.25496V0.303833H174.069V1.5588H172.642L172.602 1.61161V8.50725H173.038C175.561 8.50725 175.667 8.36193 175.799 8.08452L176.327 6.72389H177.345L177.054 9.7754Z"
                fill={themeContext.colors.red} />
            <path
                d="M188.005 1.57194H186.922L186.882 1.6248L184.174 6.15583V8.33547L184.187 8.40155L185.64 8.82428V9.77536H181.413V8.52043H182.734L182.761 8.45436V6.1162L179.96 1.6512L179.881 1.57194L178.837 1.26814V0.317017H182.404V1.57194H181.572L181.532 1.6248L183.461 5.03297H183.765L185.588 1.6248L185.561 1.57194L184.557 1.26814V0.317017H188.005V1.57194Z"
                fill={themeContext.colors.red} />
            <path
                d="M193.646 10.0001C192.655 10.0001 191.36 9.77553 190.541 9.47169L190.317 6.88254H191.466L191.902 8.40169C192.444 8.53379 193.197 8.69231 193.791 8.69231C195.31 8.69231 195.878 8.3224 195.878 7.2656C195.878 6.18238 195.35 5.95784 193.487 5.60117C191.704 5.25771 190.29 4.84818 190.29 2.94594C190.29 1.189 191.374 0.105791 193.791 0.105791C194.518 0.105791 195.376 0.251086 196.103 0.462447L197.146 0.0661621V3.07805H196.05L195.64 1.75704C195.218 1.54568 194.452 1.42679 193.831 1.42679C192.272 1.42679 191.783 1.83628 191.783 2.80061C191.783 3.75173 192.47 4.00272 194.293 4.33297C196.116 4.66323 197.371 5.09918 197.371 7.12031C197.397 8.85083 196.407 10.0001 193.646 10.0001Z"
                fill={themeContext.colors.red} />
            <path
                d="M207.847 3.46101L207.516 1.98149C207.424 1.61161 207.305 1.4531 205.429 1.4531H205.244V8.33551L205.257 8.40159L206.711 8.82432V9.7754H202.483V8.52047H203.804L203.831 8.4544V1.43988H203.567C201.559 1.43988 201.4 1.63803 201.308 2.10038L201.07 3.44783H200L200.277 0.303833H208.851V3.44783H207.847V3.46101Z"
                fill={themeContext.colors.red} />
            <path
                d="M2.2457 5.01978L6.64464 0.60764L6.90884 0.3302H6.52575H5.32364H5.25759L5.20475 0.383052L1.25495 4.49135V0.501937V0.3302H1.09643H0.158519H0V0.501937V9.61685V9.77536H0.158519H1.09643H1.25495V9.61685V5.62743L5.2708 9.72255L5.31043 9.77536H5.38969H6.5918H6.9881L6.71069 9.49796L2.2457 5.01978Z"
                fill={themeContext.colors.black} />
            <path
                d="M9.8943 0.3302H9.73578V0.501937V9.61685V9.77536H9.8943H15.4029H15.5746V9.61685V8.7582V8.58647H15.4029H10.9907V5.62743H14.6499H14.8084V5.46892V4.62346V4.46494H14.6499H10.9907V1.51909H15.4029H15.5746V1.36058V0.501937V0.3302H15.4029H9.8943Z"
                fill={themeContext.colors.black} />
            <path
                d="M25.3104 0.3302H25.2047L25.1651 0.435903L22.3646 8.17696L19.564 0.435903L19.5244 0.3302H19.4055H18.4544H18.2298L18.3091 0.554788L21.6512 9.6697L21.6776 9.77536H21.7965H22.893H23.0119L23.0515 9.6697L26.4201 0.554788L26.4993 0.3302H26.2615H25.3104Z"
                fill={themeContext.colors.black} />
            <path
                d="M30.4227 0.3302H29.498H29.3395V0.501937V9.61685V9.77536H29.498H30.4227H30.5812V9.61685V0.501937V0.3302H30.4227Z"
                fill={themeContext.colors.black} />
            <path
                d="M41.2153 0.3302H41.0568V0.501937V7.78067L36.169 0.409457L36.1294 0.3302H36.0369H34.9934H34.8216V0.501937V9.61685V9.77536H34.9934H35.8916H36.0502V9.61685V2.35133L40.9775 9.78858L41.0303 9.86784H41.1228H42.1268H42.2853V9.6961V0.501937V0.3302H42.1268H41.2153Z"
                fill={themeContext.colors.black} />
            <path
                d="M54.7952 0.145264C52.1003 0.145264 50.2245 2.17958 50.2245 5.07258C50.2245 8.04483 52.0211 9.9603 54.7952 9.9603C55.8256 9.9603 56.8824 9.6697 57.6221 9.19414L57.7146 9.14129L57.7014 9.03559L57.5297 8.11091L57.4768 7.85991L57.2655 8.01843C56.605 8.5072 55.6803 8.81104 54.8084 8.81104C52.8005 8.81104 51.5059 7.33152 51.5059 5.0594C51.5059 2.78727 52.7873 1.32094 54.782 1.32094C55.6803 1.32094 56.4993 1.55871 57.2258 2.02106L57.3976 2.12677L57.4636 1.92863L57.7542 1.09639L57.7939 0.977502L57.6882 0.911427C56.9088 0.435867 55.8256 0.145264 54.7952 0.145264Z"
                fill={themeContext.colors.black} />
            <path
                d="M64.6499 1.88901L66.1558 5.99731H63.1307L64.6499 1.88901ZM65.3632 0.435903L65.3236 0.3302H65.2047H64.1083H64.0026L63.963 0.435903L60.5019 9.55081L60.4227 9.77536H60.6604H61.6116H61.7173L61.7569 9.6697L62.6816 7.1598H66.5785L67.5032 9.6697L67.5429 9.77536H67.6618H68.6129H68.8507L68.7714 9.55081L65.3632 0.435903Z"
                fill={themeContext.colors.black} />
            <path
                d="M76.8559 3.17043C76.8559 4.33291 75.7991 4.88776 74.7555 4.92739C74.7555 4.92739 73.6987 4.96703 73.2892 4.98024V1.51918H74.5442C75.601 1.50597 76.8559 1.79659 76.8559 3.17043ZM76.0765 5.77281C77.3711 5.31046 78.1109 4.35933 78.1109 3.1308C78.1109 1.36066 76.8427 0.343506 74.6366 0.343506H72.1928H72.0343V0.50202V9.61693V9.77544H72.1928H73.1175H73.276V9.61693V6.11628L74.848 6.01062L77.1333 9.69619L77.1862 9.77544H77.2787H78.3619H78.6525L78.494 9.52449L76.0765 5.77281Z"
                fill={themeContext.colors.black} />
            <path
                d="M86.7634 3.17043C86.7634 4.33291 85.7066 4.88776 84.663 4.92739C84.663 4.92739 83.6062 4.96703 83.1967 4.98024V1.51918H84.4517C85.5085 1.50597 86.7634 1.79659 86.7634 3.17043ZM85.9973 5.77281C87.2918 5.31046 88.0316 4.35933 88.0316 3.1308C88.0316 1.36066 86.7634 0.343506 84.5574 0.343506H82.1135H81.955V0.50202V9.61693V9.77544H82.1135H83.0514H83.2099V9.61693V6.11628L84.7819 6.01062L87.0673 9.69619L87.1201 9.77544H87.2126H88.2958H88.5864L88.4279 9.52449L85.9973 5.77281Z"
                fill={themeContext.colors.black} />
            <path
                d="M95.7595 8.81104C93.7515 8.81104 92.3513 7.25226 92.3513 5.01977C92.3513 2.8269 93.7383 1.30775 95.7066 1.30775C97.7014 1.30775 99.1016 2.85331 99.1016 5.0858C99.0884 7.27867 97.7146 8.81104 95.7595 8.81104ZM95.7198 0.145264C93.025 0.145264 91.0567 2.21921 91.0567 5.07258C91.0567 7.89952 93.0118 9.9603 95.6934 9.9603C98.3883 9.9603 100.343 7.88634 100.343 5.01977C100.37 2.19282 98.4147 0.145264 95.7198 0.145264Z"
                fill={themeContext.colors.black} />
            <path
                d="M105.337 8.57328V0.501937V0.3302H105.178H104.24H104.082V0.501937V9.61685V9.77536H104.24H109.696H109.855V9.61685V8.7318V8.57328H109.696H105.337Z"
                fill={themeContext.colors.black} />
            <path
                d="M118.851 8.57328H114.505V0.501937V0.3302H114.346H113.408H113.25V0.501937V9.61685V9.77536H113.408H118.851H119.009V9.61685V8.7318V8.57328H118.851Z"
                fill={themeContext.colors.black} />
        </WordMark>
    )
}

const WordMark = styled.svg`
    position: absolute;
    width: 209px;
    height: 10px;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);

    @media (min-width: 375px) {
        left: 24px;
        width: 250px;
        height: 12px;
    }

    @media (min-width: 768px) {
        left: 32px;
        width: 334px;
        height: 16px;
    }

    @media (min-width: 1024px) {
       
    }
`;

export default HeaderLogo;